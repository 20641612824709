<template>
  <div>
    <div class="flex flex-wrap justify-between">
      <label for="language">Choose language:
        <select
          class="p-2 outline-none"
          v-model="lang"
          @change="getData(lang,page)"
          name="language"
        >
          <option value="en">English</option>
          <option value="ru">Russian</option>
          <option value="az">Azerbaijani</option>
        </select>
      </label>
      <label for="pages">Choose page
        <select
          class="p-2 outline-none"
          v-model="page"
          @change="getData(lang,page)"
          name="pages"
        >
          <option v-for="i in pages" :key="i" :value="i">{{_.capitalize(i.replaceAll('-', ' '))}}</option>
        </select>
      </label>
    </div>
    <div class="p-4">
      <Table
        :lang="lang"
        :columnNames="['title', 'text', 'image']"
        :keys="['title', 'text', 'image', 'url']"
        @getData="getData(lang,page)"
        :requests="requests"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { HTTP } from '../axios/axios'
import Table from '@/components/Table.vue'
import requests from '../services/requests/HoverBlocksRequests.js'

export default {
  data () {
    return {
      lang: 'en',
      page: 'home',
      pages: ['home'],
      requests: requests
    }
  },
  name: 'HoverBlocks',
  components: {
    Table
  },
  methods: {
    // gets data from backend
    async getData (lang, page) {
      this.$emit('loading', true)
      const temp = await this.requests.getAll(lang, page)
      temp && temp.hoverBlocks && this.$store.commit('setData', temp.hoverBlocks)
      this.$emit('loading', false)
    },
    async setPages () {
      const res = await HTTP.get('/hover-blocks/pages')
      this.pages = res.data
      for (const i in this.pages) { this.pages[i] = this.pages[i].substring(1, this.pages[i].length) }
    }
  },
  computed: {
    _ () {
      return _
    }
  },
  created () {
    // fills table with data from backend on created
    this.getData(this.lang, this.page)
    this.setPages()
  }
}
</script>
