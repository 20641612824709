import { HTTP } from '../../axios/axios'
let data

async function getAll (lang, page) {
  try {
    const res = await HTTP.get('/hover-blocks/pages/' + page, { headers: { 'accept-language': lang } })
    data = res.data
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (hoverBlock, lang) {
  return await HTTP.patch('/hover-blocks/' + data._id, { hoverBlocks: [...data.hoverBlocks, hoverBlock] })
}
async function update (hoverBlock, id) {
  return await HTTP.patch('/hover-blocks/' + data._id, data)
}
async function deleteOne (hoverBlock) {
  const hoverBlocks = data.hoverBlocks.filter(i => i._id !== hoverBlock._id)
  return await HTTP.patch('/hover-blocks/' + data._id, { hoverBlocks })
}
async function deleteMany (ids) {
  const hoverBlocks = data.hoverBlocks.filter(i => ids.indexOf(i._id) === -1)
  return await HTTP.patch('/hover-blocks/' + data._id, { hoverBlocks })
}
async function changeOrder (hoverBlocks) {
  return await HTTP.patch('/hover-blocks/' + data._id, { hoverBlocks })
}

export default { getAll, deleteMany, addOne, update, deleteOne, changeOrder }
